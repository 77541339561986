import packageJson from '../../package.json';

export const environment = {
  appVersion: packageJson.version + '-stage',
  production: false,
  googleTagContainerId: 'GTM-NHDBCX7',
  envName: 'stage',
  ondemandUser: 'NO-USER',
  javaApiUrl: 'https://stage-ctrl-api.nexxen.com/api',
  javaApiViaKongUrl: 'https://stage-ctrl-api.nexxen.com/api',
  javaInsightsUrl: 'https://stage-ctrl-api.nexxen.com/insights',
  spearadUrl: 'https://console.dev.spearad.video/login',
  spearadIframeUrl: 'https://console.dev.spearad.video',
  exchangeDealsUrl: 'https://stage-rx-deals.nexxen.com/api',
  sentinelApi: 'https://dev-api.unruly.co/dev/sentinel',
  captcha: false,
  pendo: false,
  datadog: false,
  authServer: 'https://dev-api.unruly.co/realms/fusion-stage/protocol/openid-connect',
  zendeskUrl: 'https://unrulyssp-help.zendesk.com'
};
