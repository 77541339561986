import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `
    <img class="app-icon" src="/resources/20241014111521/assets/svg/{{icon}}.svg" *ngIf="icon">
  `,
  styles: [`
    :host-context(app-icon) {
      display: inline-flex;
    }
    .app-icon {
      width: 100%;
    }
  `]
})
export class AppIcon {

  @Input() icon: string = null;

}
